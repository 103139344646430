<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="quotationData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching quotation data
      </h4>
      <div class="alert-body">
        No se encontró ninguna cotización con este ID.
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
      v-if="quotationData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    Dramox
                  </h3>
                </div>
                <p class="card-text mb-25">
                  <b> Asunto:</b>  {{ quotationData.subject }}
                </p>
                <p class="card-text mb-25">
                  <b> Vendedor asignado:</b>  {{ quotationData.seller }}
                </p>
                <!--                                <p class="card-text mb-25">-->
                <!--                                  San Diego County, CA 91905, USA-->
                <!--                                </p>-->
                <!--                                <p class="card-text mb-0">-->
                <!--                                  +1 (123) 456 7891, +44 (876) 543 2198-->
                <!--                                </p>-->
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Cotización:
                  <span class="invoice-number">{{ quotationData.quotationCode }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    F.Creación
                  </p>
                  <p class="invoice-date">
                    {{ quotationData.created_at }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Actualizado
                  </p>
                  <p class="invoice-date">
                    {{ quotationData.updated_at }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Vencimiento
                  </p>
                  <p class="invoice-date">
                    {{ quotationData.expirationDate }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="quotationData.client"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">
              <!-- Col: Quotation To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Cotización a:
                </h6>
                <h6 class="mb-25">
                  {{ quotationData.client }}
                </h6>
                <h6 class="mb-25">
                  {{ quotationData.contact.position }}
                </h6>
                <h6 class="mb-25">
                  {{ quotationData.business_name }}
                </h6>
                <h6 class="mb-25">
                  {{ quotationData.address }}
                </h6>
                <p class="card-text mb-25">
                  {{ quotationData.contact.phone }}
                </p>
                <p class="card-text mb-25">
                  {{ quotationData.contact.phone }} - {{ quotationData.contact.mobilephone }}
                </p><p class="card-text mb-2">
                  {{ quotationData.contact.email }}
                </p>
                <!--                <p class="card-text mb-0">-->
                <!--                  {{ quotationData.client.companyEmail }}-->
                <!--                </p>-->
              </b-col>

              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Condiciones de pago:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total a pagar:
                        </td>
                        <td><span class="font-weight-bold">{{ currencyData.symbol }} {{ totalPayQuotation.toFixed(2) }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Tiempo entrega:
                        </td>
                        <td><span class="font-weight-bold">{{ quotationData.deliveryTime }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Forma de pago:
                        </td>
                        <td>{{ quotationData.methodPayment }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Método de pago:
                        </td>
                        <td>{{ quotationData.paymentMethod }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>

          </b-card-body>

          <!-- Quotation Description: Table -->
          <b-table
            responsive="sm"
            :items="quotationData.items"
            :fields="fieldsColumns"
          >
            <template #cell(name)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.name }}
              </b-card-text>
            </template>

            <template #cell(cost)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ (data.item.cost).toFixed(2) }}
                <p>{{ data.item.discount }}%</p>
              </b-card-text>
            </template>

            <template #cell(price_neto)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ (data.item.cost*(1-data.item.discount/100) * data.item.qty).toFixed(2) }}
              </b-card-text>
            </template>
          </b-table>

          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="12"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="6"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal ({{ currencyData.symbol }}):
                    </p>
                    <p class="invoice-total-amount">
                      {{ totalQuotation.toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Dsct ({{ quotationData.discountType === 'percentage' ? `${quotationData.discount}%` : currencyData.symbol }}):
                    </p>
                    <p class="invoice-total-amount">
                      {{ discountQuotation.toFixed(2) }}
                    </p>
                  </div>

                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Valor venta ({{ currencyData.symbol }}):
                    </p>
                    <p class="invoice-total-amount">
                      {{ subtotalQuotation.toFixed(2) }}
                    </p>
                  </div>

                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      IGV(18%) ({{ currencyData.symbol }}):
                    </p>
                    <p class="invoice-total-amount">
                      {{ taxesQuotation.toFixed(2) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total a pagar ({{ currencyData.symbol }}):
                    </p>
                    <p class="invoice-total-amount">
                      {{ totalPayQuotation.toFixed(2) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Observaciones: </span>
            <span>{{
              quotationData.observation
            }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="isHandlerSidebarActive = true"
          >
            Enviar email
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="openPDF(quotationData)"
          >
            Descargar
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimir
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: 'quotations-edit', params: { id: $route.params.id } }"
          >
            Editar
          </b-button>

          <!-- Button: Add Payment -->
          <b-button
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="changeToSale(quotationData)"
          >
            Agregar a venta
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <!--    <invoice-sidebar-send-invoice />-->
    <!--    <invoice-sidebar-add-payment />-->
    <quotation-sidebar-send-email
      :quotation="quotationData"
      :is-handler-sidebar-active.sync="isHandlerSidebarActive"
    />
  </section>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BCardText, BButton, BAlert, BLink, VBToggle, BTable,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import quotationStoreModule from '@/views/dramox/quotation/quotationStoreModule'
import QuotationSidebarSendEmail from '@/views/dramox/quotation/view/QuotationSidebarSendEmail.vue'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    Logo,
    BTable,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BAlert,
    BLink,

    QuotationSidebarSendEmail,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-quotations'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, quotationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const isHandlerSidebarActive = ref(false)
    const fieldsColumns = ref([
      { key: 'name', label: 'Item' },
      { key: 'qty', label: 'Cantidad' },
      { key: 'cost', label: 'Precio Venta' },
      {
        key: 'price_neto',
        label: 'Precio neto',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ])

    const quotationData = ref({
      clientId: null,
      subject: '',
      expirationDate: null,
      deliveryTime: '',
      methodPayment: '',
      // ? Set single Item in form for adding data
      items: [],
      observation: '',
      discount: 0,
      discountType: 'percentage',
    })

    const totalQuotation = computed(() => +(quotationData.value.items.reduce((acc, sum) => acc + ((sum.cost * (1 - sum.discount / 100)) * sum.qty), 0)))
    const discountQuotation = computed(() => {
      if (quotationData.value.discountType === 'percentage') {
        return +(totalQuotation.value * (quotationData.value.discount / 100)).toFixed(2)
      }
      return +(+quotationData.value.discount).toFixed(2)
    })
    const subtotalQuotation = computed(() => {
      if (quotationData.value.discountType === 'percentage') {
        return +((1 - quotationData.value.discount / 100) * totalQuotation.value).toFixed(2)
      }
      return +(totalQuotation.value - quotationData.value.discount).toFixed(2)
    })
    const taxesQuotation = computed(() => +(0.18 * subtotalQuotation.value).toFixed(2))
    const totalPayQuotation = computed(() => +(taxesQuotation.value + subtotalQuotation.value).toFixed(2))
    const currencyData = computed(() => ({
      symbol: quotationData.value.currency === 'pen' ? 'S/' : '$',
      currency: quotationData.value.currency,
    }))

    store.dispatch('app-quotations/fetchPreviewQuotation', { id: router.currentRoute.params.id })
      .then(response => {
        const { quotation } = response.data.payload.results
        quotationData.value = quotation
        fieldsColumns.value[2].label = `Precio venta (${quotation.currency_symbol})`
        fieldsColumns.value[3].label = `Precio Neto (${quotation.currency_symbol})`
      })
      .catch(error => {
        if (error.response.status === 404) {
          quotationData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    const openPDF = val => {
      const url = `${axios.defaults.baseURL}admin/pdf/quotation/${val.id}`
      window.open(url, '_blanck')
    }

    const changeToSale = item => {
      store.dispatch('app-quotations/changeQuotationToSale', { id: item.id })
        .then(() => {
          showNotification({ title: 'Venta agregada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          router.push({ name: 'sales' })
        })
        .catch(error => {
          showNotification({
            title: 'Oops!, ocurrió un error', text: error.response.data.error.message, icon: 'AlertTriangleIcon', variant: 'danger',
          })
        })
    }

    return {
      quotationData,
      printInvoice,

      fieldsColumns,

      totalQuotation,
      discountQuotation,
      subtotalQuotation,
      totalPayQuotation,
      currencyData,
      taxesQuotation,

      openPDF,
      isHandlerSidebarActive,
      changeToSale,

    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
